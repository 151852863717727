<template>
  <main class="timetables">

    <h2><router-link class="back" to="/lines"></router-link> Timetable {{id}}</h2>

    <template v-if="simpleJourneys">
      <ul class="directions" v-for="(journey, journeyindex) in simpleJourneys" v-bind:key="'dir'+journeyindex">
        <li>Direction <a :href="'#dir'+journey.EstimatedVehicleJourney[0].DirectionRef">{{ journey.EstimatedVehicleJourney[0].EstimatedCalls[0].DestinationName }}</a></li>
      </ul>
      <div v-for="(journey, journeyindex) in simpleJourneys" v-bind:key="'dirlist'+journeyindex">
        <h3>{{ journey.EstimatedVehicleJourney[0].EstimatedCalls[0].DestinationName }}</h3>
        <table class="timetable" :id="'dir'+journey.EstimatedVehicleJourney[0].DirectionRef">
          <tr>
            <th>Arrêt</th>
            <th>Arrivée</th>
            <th>Départ</th>
          </tr>
          <tr
            v-for="(call, index) in journey.EstimatedVehicleJourney[0].EstimatedCalls"
            :key="index">
            <td v-if="call.StopPointName" class="stop"><router-link :to="'/stop/'+call.StopPointRef">{{call.StopPointName}}</router-link></td>
            <!-- <td v-if="call.DestinationName">Destination : {{call.DestinationName}}</td> -->
            <td v-if="call.ExpectedArrivalTime">{{call.ExpectedArrivalTime | timize }} {{call.ExpectedArrivalTime | estimatein }}</td>
            <td v-if="call.ExpectedDepartureTime">{{call.ExpectedDepartureTime | timize }} {{call.ExpectedDepartureTime | estimatein }}</td>
          </tr>
        </table>
      </div>
    </template>
    <Loader v-else-if="simpleJourneys !== false" class="loading">...Chargement en cours ...</Loader>
    <p class="error" v-else>Impossible de charger ces données <span>☹</span></p>

  </main>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  name: 'Timetable',
  head: {
    title: 'Directs',
    titleTemplate: '%s - Timetable',
    htmlAttrs: {
      lang: 'fr'
    }
  },
  components: {
    Loader
  },
  mounted() {
    this.$store.commit('clearTimetable');
    this.$store.dispatch('fetchLines', false);
    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$store.dispatch('fetchTimetable', this.id);
    }
  },
  data() {
    return {
      id: null
    }
  },
  computed: {
    simpleJourneys() {
      if (this.$store.state.timetable && this.$store.state.timetable.ServiceDelivery) {
        if (this.$store.state.timetable.ServiceDelivery.EstimatedTimetableDelivery
        && this.$store.state.timetable.ServiceDelivery.EstimatedTimetableDelivery[0]
        && this.$store.state.timetable.ServiceDelivery.EstimatedTimetableDelivery[0].EstimatedJourneyVersionFrame) {
          return this.$store.state.timetable.ServiceDelivery.EstimatedTimetableDelivery[0].EstimatedJourneyVersionFrame
        } else {
          return false
        }
      }
      return null
    }
  },
  methods: {
    lineStyle(lineName) {
      if (this.$store.state.lines) {
        let line = this.$store.state.lines.LinesDelivery.AnnotatedLineRef.filter((l) => { return l.LineRef == lineName })[0]
        if (line) {
          const extension = line.Extension
          if (extension)
          return {
            color:'#'+extension.RouteTextColor,
            backgroundColor:'#'+extension.RouteColor
          }
        }
      }
    },
  }
};
</script>

<style lang="scss">

@import "@/assets/global.scss";

.timetable {
  background: #fff;
  margin: 1rem;
  td {
    padding: .5rem;
  }
  th {
    padding: 1rem .5rem;
  }
  tr {
    border-bottom: 1px solid $separator-color;
  }
}

h3 {
  margin: 1rem;
}

.directions {
  text-align: center;
}

</style>
